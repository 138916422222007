import React, { Fragment } from "react"
import styled from "styled-components"
import { graphql } from "gatsby";

import Content from "../components/content"
import Nav from "../components/nav"
import StyledA from "../components/styleda"
import Responsive from "../components/responsive"

import { GatsbyImage, getImage } from "gatsby-plugin-image";

const scalaDaysTalks = {
  2012: "http://skillsmatter.com/podcast/scala/making-games-and-solving-puzzles-in-scala",
  2013: "https://www.youtube.com/watch?v=eyIbQn-mhsc",
  2014: "https://www.youtube.com/watch?v=YuYSvoFY0dM",
  2015: "https://www.youtube.com/watch?v=tbcXoSMsOGg",
  2016: "https://www.youtube.com/watch?v=cngpLXViFCE",
  2017: "https://www.youtube.com/watch?v=l8cR60eXEPo",
  2018: "https://www.youtube.com/watch?v=MxRigUFmeVw"
}

const news = [
  [new Date("6/30/2022"), <>Our paper <StyledA href="https://www.shadaj.me/papers/katara.pdf">Synthesizing CRDTs from Sequential Data Types with Verified Lifting</StyledA> has been conditionally accepted to <StyledA href="https://2022.splashcon.org/track/splash-2022-oopsla">OOPSLA 2022</StyledA>!</>],
  [new Date("1/1/2022"), <>Attending CIDR 2022, and learning how to pitch programming languages research to the database community</>],
  [new Date("8/1/2021"), <>Started my PhD at UC Berkeley in the <StyledA href="https://rise.cs.berkeley.edu">RISELab</StyledA>, working on the <StyledA href="https://hydro-project.github.io">Hydro project</StyledA> under Alvin Cheung and Joe Hellerstein</>],
  [new Date("4/1/2021"), <>Received the <StyledA href="https://www2.eecs.berkeley.edu/Students/Awards/113/">EECS Major Citation</StyledA> award!</>],
  [new Date("3/1/2021"), <>Awarded the <StyledA href="https://www.nsfgrfp.org">NSF Graduate Research Fellowship!</StyledA></>]
];

const MyName = styled.span`
  font-weight: 600;
`;

const papers = [
  {
    title: "Katara: Synthesizing CRDTs with Verified Lifting",
    authors: <><MyName>Shadaj Laddad</MyName>, Conor Power, Mae Milano, Alvin Cheung, Joseph M. Hellerstein</>,
    conference: <>OOPSLA 2022 (<StyledA href="/papers/katara.pdf">preprint</StyledA>)</>
  },
  {
    title: "VizSmith: Automated Visualization Synthesis by Mining Data-Science Notebooks",
    authors: <>Rohan Bavishi, <MyName>Shadaj Laddad</MyName>, Hiroaki Yoshida, Mukul R. Prasad, Koushik Sen</>,
    conference: <>ASE 2021 (<StyledA href="https://ieeexplore.ieee.org/document/9678696">IEEE</StyledA>, <StyledA href="/papers/ase2021-vizsmith.pdf">pdf</StyledA>)</>
  },
  {
    title: "Fluid Quotes: Metaprogramming across Abstraction Boundaries with Dependent Types",
    authors: <><MyName>Shadaj Laddad</MyName> and Koushik Sen</>,
    conference: <>GPCE 2020 (<StyledA href="https://dl.acm.org/doi/10.1145/3425898.3426953">ACM</StyledA>, <StyledA href="/papers/fluid-quotes.pdf">pdf</StyledA>)</>
  },
  {
    title: "ScalaPy: Seamless Python Interoperability for Cross-Platform Scala Programs",
    authors: <><MyName>Shadaj Laddad</MyName> and Koushik Sen</>,
    conference: <>Scala Symposium 2020 (<StyledA href="https://dl.acm.org/doi/abs/10.1145/3426426.3428485">ACM</StyledA>, <StyledA href="/papers/scalapy.pdf">pdf</StyledA>)</>
  }
];

const NoMarginP = styled.p`
  margin: 0;
`;

class RotatePictures extends React.Component {
  constructor(props) {
    super(props);
    this.state = { index: 0 };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState(s => {
        if (s.index + 1 >= this.props.images.length) {
          return { index: 0 };
        } else {
          return { index: s.index + 1 };
        }
      });
    }, 6000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = undefined;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.index >= this.props.images.length) {
      this.setState({ index: 0 });
    }
  }

  render() {
    const nextIndex = (this.state.index + 1) % this.props.images.length;
    const prevIndex = (this.state.index - 1 + this.props.images.length) % this.props.images.length;
    return (<>
      {this.props.children(this.props.images[prevIndex], 1, 1, prevIndex)}
      {this.props.children(this.props.images[this.state.index], 1, 2, this.state.index)}
      {this.props.children(this.props.images[nextIndex], 0, 3, nextIndex)}
    </>);
  }
}

const IndexTitle = styled.h2`
  font-family: 'Arvo', serif;
  font-size: 70px;
  @media (max-width: 800px) {
    font-size: 60px;
    margin-top: 30px;
  }
  font-weight: 700;
  margin-top: 35px;
  margin-bottom: 10px;
`;


const IndexSubtitle = styled.h2`
  font-family: 'Arvo', serif;
  font-size: 40px;
  @media (max-width: 800px) {
    font-size: 30px;
  }
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 20px;
`;

export default class Index extends React.Component {
  render() {
    const scalaDaysParents = Object.keys(scalaDaysTalks).map((key, i) => {
      return <Fragment key={i}>
        <StyledA href={scalaDaysTalks[key]}>{key}</StyledA>
        {(i < Object.keys(scalaDaysTalks).length - 1) && <span>, </span>}
      </Fragment>;
    });

    const mobileImages = [this.props.data.yellowstone, this.props.data.roboticsCnc, this.props.data.beach, this.props.data.graphqlSummit];
    const desktopImages = [...mobileImages, this.props.data.sfScalaInterview];

    return (
      <div>
        <Nav current="about"/>
        <Content>
          <IndexTitle>Hi, I'm Shadaj</IndexTitle>
          <IndexSubtitle>I explore the boundaries of programming</IndexSubtitle>

          <div style={{
            width: "100%",
            height: "550px",
            position: "relative",
            borderRadius: "30px",
            overflow: "hidden",
            transform: "translateZ(0)"
          }}>
            <Responsive comp={(big) => {
              return <RotatePictures images={big ? desktopImages : mobileImages}>
                {(image, opacity, index, key) => {
                  return <GatsbyImage image={getImage(image)} style={{
                    position: "absolute",
                    top: 0, left: 0,
                    width: "100%",
                    height: "550px",
                    opacity,
                    objectFit: "cover",
                    transition: "opacity 1.5s ease-in-out",
                    WebkitTransition: "opacity 1.5s ease-in-out",
                    zIndex: index
                  }} key={key} alt=""/>;
                }}
              </RotatePictures>
            }}/>
          </div>

          <div style={{ marginTop: 20 }}>
            I'm a first-year PhD student co-advised by <StyledA href="https://people.eecs.berkeley.edu/~akcheung/">Alvin Cheung</StyledA> and <StyledA href="https://dsf.berkeley.edu/jmh/">Joe Hellerstein</StyledA> in the <StyledA href={"https://sky.cs.berkeley.edu"}>Sky Computing Lab</StyledA> at UC Berkeley, where I research programming languages and distributed systems.{/* I explore the boundaries of <b>type systems, functional programming, and program synthesis</b> along with attendant innovations that <b>tie these concepts to distributed systems</b> to make the development of large scale applications <b>efficient, scalable, and enjoyable</b>. */} Most recently, I've developed program synthesis algorithms that can automatically create <StyledA href={"/papers/katara.pdf"}>provably-correct CRDT designs</StyledA>, which enable state replication with low-latency and high-throughput. I'm grateful to be supported by an <StyledA href="https://www.nsfgrfp.org/">NSF Graduate Research Fellowship</StyledA>.

            <p/>

            Previously, I received my BS in EE/CS at Berkeley, where I was fortunate to work with <StyledA href="https://people.eecs.berkeley.edu/~ksen">Koushik Sen</StyledA> on new approaches to compile-time metaprogramming and program synthesis for data science tasks. I've interned at <StyledA href="https://github.com/tensorflow/swift/blob/master/proposals/modern-layer-api/LayerApiPrototypes.md">Google Brain</StyledA>, <StyledA href="https://engineering.fb.com/category/production-engineering/">Facebook</StyledA>, <StyledA href="https://blog.apollographql.com/tutorial-graphql-subscriptions-client-side-40e185e4be76">Apollo GraphQL</StyledA>, <StyledA href="http://engineering.khanacademy.org/posts/interning-at-khan-academy.htm">Khan Academy</StyledA>, <StyledA href="https://building.coursera.org/blog/2014/08/14/my-internship-at-coursera/">Coursera</StyledA>, and <StyledA href="https://github.com/payalabs/scalajs-react-mdl">Paya Labs</StyledA>, where I worked with GraphQL, Rust, React, and Scala.js to develop open-source libraries, education platforms, and large-scale infrastructure.

            <p/>

            I host many open-source libraries and projects on <StyledA href="http://github.com/shadaj">GitHub</StyledA>, and have a <StyledA href="http://youtube.com/shadajProgramming">channel on YouTube</StyledA>.
            I've presented a keynote at <StyledA href="https://www.youtube.com/watch?v=Co8V3VSRvYM">OSCON 2014</StyledA> and at Scala Days ({scalaDaysParents}), <StyledA href="https://www.youtube.com/watch?v=cZtQgK83AqQ">GraphQL Summit</StyledA>, <StyledA href="https://www.youtube.com/watch?v=aMjyhjJ-pJc">Scale By the Bay</StyledA>, and various other technical conferences.
            In high school, I was the Co-President of <StyledA href="http://lynbrookrobotics.com/">Lynbrook Robotics</StyledA>, where I led a competitive robotics team of over 100 students. I continue to be involved with the team, where I focus on introducing students to advanced control theory topics and software design principles.
          </div>

          <div style={{
            marginTop: "30px",
            borderTop: "1px solid #a0a0a0"
          }}/>

          <IndexSubtitle style={{
            marginTop: "30px",
            textAlign: "center"
          }}>Latest News</IndexSubtitle>
          <div style={{
            maxWidth: "900px",
            marginLeft: "auto",
            marginRight: "auto"
          }}>
            {news.map(([date, text], i) => {
              return <div style={{
                marginTop: i > 0 ? "10px" : undefined
              }} key={i}>
                <div style={{
                  display: "inline-block",
                  width: "90px",
                  fontWeight: "300",
                  textAlign: "right",
                  paddingRight: "10px"
                }}>{new Intl.DateTimeFormat("default", {
                  month: "short",
                  year: "numeric",
                }).format(date)}</div>
                <div style={{
                  display: "inline-block",
                  width: "calc(100% - 100px)",
                  verticalAlign: "top"
                }}>{text}</div>
              </div>
            })}
          </div>

          <div style={{
            marginTop: "30px",
            borderTop: "1px solid #a0a0a0"
          }}/>

          <IndexSubtitle style={{
            marginTop: "30px",
            textAlign: "center"
          }}>Publications</IndexSubtitle>
          <div style={{
            maxWidth: "900px",
            marginLeft: "auto",
            marginRight: "auto"
          }}>
            {papers.map((paper, i) => {
              return <div style={{
                marginTop: i > 0 ? "15px" : undefined,
              }} key={i}>
                <NoMarginP style={{ fontSize: "22px" }}>{paper["title"]}</NoMarginP>
                <NoMarginP style={{ fontWeight: 300 }}>{paper["authors"]}</NoMarginP>
                <NoMarginP style={{ fontStyle: "italic" }}>{paper["conference"]}</NoMarginP>
              </div>;
            })}
          </div>

          <div style={{
            marginTop: "30px",
            borderTop: "1px solid #a0a0a0"
          }}/>

          <IndexSubtitle style={{
            marginTop: "30px",
            textAlign: "center"
          }}>Fellowships and Awards</IndexSubtitle>
          <div style={{
            maxWidth: "900px",
            marginLeft: "auto",
            marginRight: "auto"
          }}>
            <ul style={{ paddingLeft: "20px" }}>
              <li><StyledA href="https://www.nsfgrfp.org">NSF Graduate Research Fellowship</StyledA> (2021)</li>
              <li><StyledA href="https://www2.eecs.berkeley.edu/Students/Awards/113/">UC Berkeley EECS Major Citation</StyledA> (2021)</li>
              <li><StyledA href="https://grad.berkeley.edu/flas">UC Berkeley Foreign Language and Area Studies Fellowship</StyledA> (2019)</li>
              <li><StyledA href="https://ndseg.org">NDSEG Graduate Fellowship</StyledA> - Honorable Mention (2022)</li>
              <li><StyledA href="https://neo.com">Neo Scholar</StyledA> (2020)</li>
              <li><StyledA href="https://hkn.eecs.berkeley.edu">UC Berkeley Eta Kappa Nu</StyledA> (2019)</li>
              <li><StyledA href="https://engineering.berkeley.edu/students/undergraduate-guide/policies-procedures/academic-honors">UC Berkeley College of Engineering Dean's List</StyledA> (2019)</li>
            </ul>
          </div>
        </Content>
      </div>
    )
  }
}


export const query = graphql`
  query {
    yellowstone: file(relativePath: { eq: "img/yellowstone.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    roboticsCnc: file(relativePath: { eq: "img/robotics-cnc.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    sfScalaInterview: file(relativePath: { eq: "img/sfscala-interview.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    beach: file(relativePath: { eq: "img/beach.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    graphqlSummit: file(relativePath: { eq: "img/graphql-summit.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
